// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1pQhgNdm124UHESgQSUu4J,._1pQhgNdm124UHESgQSUu4J *{box-sizing:border-box}._1pQhgNdm124UHESgQSUu4J{display:flex;justify-content:center;align-items:center;z-index:9999}._1pQhgNdm124UHESgQSUu4J .U9vxHV_8Lp4CLQy0pSEPc{background:#fff;min-width:15%}._1pQhgNdm124UHESgQSUu4J .U9vxHV_8Lp4CLQy0pSEPc ._2WD7nTDvYftu2u11wp52JM{padding:2em}._1M7_Pnrda_LrMoowbJ8IDa{background:rgba(0,0,0,.5);z-index:999}._1M7_Pnrda_LrMoowbJ8IDa,._1pQhgNdm124UHESgQSUu4J{position:fixed;top:0;right:0;bottom:0;left:0}", "",{"version":3,"sources":["webpack://./components/UiKit/base.scss","webpack://./components/UiKit/Modal.scss"],"names":[],"mappings":"AAGE,oDACE,qBAAA,CCDJ,yBAEE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,YAAA,CAEA,gDACE,eAAA,CACA,aAAA,CAEA,yEACE,WAAA,CAKN,yBACE,yBAAA,CACA,WAAA,CAGF,kDACE,cAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA","sourcesContent":["@use \"variables\";\n\n%uikit {\n  &, * {\n    box-sizing: border-box;\n  }\n}\n","@use \"variables\";\n@import \"base\";\n\n.modal {\n  @extend %uikit;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 9999;\n\n  .container {\n    background: white;\n    min-width: 15%;\n\n    .content {\n      padding: 2em;\n    }\n  }\n}\n\n.backdrop {\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 999;\n}\n\n.backdrop, .modal  {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_1pQhgNdm124UHESgQSUu4J",
	"container": "U9vxHV_8Lp4CLQy0pSEPc",
	"content": "_2WD7nTDvYftu2u11wp52JM",
	"backdrop": "_1M7_Pnrda_LrMoowbJ8IDa"
};
export default ___CSS_LOADER_EXPORT___;
