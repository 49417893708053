import { Size, Tint } from './utils';
import cx from 'classnames';
import styles from './Input.scss?module';
import React, { RefObject, useContext } from 'react';
import { Context } from './Field';

interface Props {
    tint?: Tint;
    size?: Size;
    innerRef?: RefObject<HTMLInputElement>;

    className?: string;
}

function inputProps({ tint = 'default', size = 'm', className = '', innerRef, ...props }: Props) {
    return {
        'data-tint': tint,
        'data-size': size,
        className: cx(styles.input, className),
        ref: innerRef,
        ...props,
    };
}

type TextInput = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'> &
    Props;
export function TextInput(props: TextInput) {
    const fieldProps = useContext(Context) || {};
    return <input {...inputProps({ ...fieldProps, ...props })} />;
}
