import * as Cookies from 'js-cookie';
import { PLATFORM_HOST } from './const';

const key = 'authorization';

export function storeToken(token: string) {
    Cookies.set(key, token, {
        domain: PLATFORM_HOST,
    });
}

export function deleteToken() {
    Cookies.remove(key, {
        domain: PLATFORM_HOST,
    });
}

export function getToken() {
    const token = Cookies.get(key);

    if (token === '') {
        deleteToken();
        return null;
    }

    return token;
}
