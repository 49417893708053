import React from 'react';
import cx from 'classnames';
import styles from './Button.scss?module';
import { Size, Tint } from './utils';

type Appearance = 'solid' | 'light';

interface Props {
    tint?: Tint;
    appearance?: Appearance;
    size?: Size;

    className?: string;
}

function btnProps({ className = '', tint = 'default', appearance = 'solid', size = 'm', ...props }: Props) {
    let appearanceClassName: string | undefined = undefined;
    switch (appearance) {
        case 'solid':
            appearanceClassName = styles.solid;
            break;
        case 'light':
            appearanceClassName = styles.light;
            break;
    }

    return {
        'data-tint': tint,
        'data-size': size,
        className: cx(styles.btn, appearanceClassName, className),
        ...props,
    };
}

export type LinkButtonProps = Props &
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
export function LinkButton(props: LinkButtonProps) {
    return <a {...btnProps(props)} />;
}

export type DivButtonProps = Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export function DivButton(props: DivButtonProps) {
    return <div {...btnProps(props)} />;
}

export type ButtonProps = Props &
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
export default function Button(props: ButtonProps) {
    return <button type={'button'} {...btnProps(props)} />;
}
