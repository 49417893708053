import React, { ReactNode } from 'react';
import styles from './Modal.scss?module';

interface Props {
    children: ReactNode;
}

export default function Modal({ children }: Props) {
    return (
        <>
            <div className={styles.modal}>
                <div className={styles.container}>
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
            <div className={styles.backdrop} />
        </>
    );
}
