import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { gql } from 'apollo-boost';
import { AuthUser } from '../generated/AuthUser';

export const Context = createContext<{
    user: AuthUser | null;
    setUser: Dispatch<SetStateAction<AuthUser>>;
    setToken: Dispatch<SetStateAction<string | null>>;
    logout: () => void;
    refresh: () => Promise<void>;
}>({
    user: null,
    setUser: () => undefined,
    setToken: () => undefined,
    logout: () => undefined,
    refresh(): Promise<void> {
        return Promise.resolve(undefined);
    },
});

export const AUTH_USER_FRAGMENT = gql`
    fragment AuthUser on Viewer {
        id
        email
        isAdmin
        otpEnabled
        otpSecret
    }
`;

export function useAuth() {
    return useContext(Context);
}

export default function useAuthUser() {
    return useContext(Context).user;
}
