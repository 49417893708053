import { gql } from 'apollo-boost';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { AuthUser } from '../../generated/AuthUser';
import { deleteToken, getToken, storeToken } from '../../utils/token';
import client from '../../utils/apollo';
import { AuthProviderTokenCheck } from '../../generated/AuthProviderTokenCheck';
import { useAuthChange } from '../../utils/auth_intercept';
import Loading from '../Loading';
import LoginModal from './LoginModal';
import Redirect from '../../utils/Redirect';
import { APP_HOST } from '../../utils/const';
import { loginPath } from '../../utils/apppaths';
import useAuthUser, { AUTH_USER_FRAGMENT, Context } from '../../hooks/useAuth';

const VIEWER_QUERY = gql`
    query AuthProviderTokenCheck {
        viewer {
            ...AuthUser
        }
    }
    ${AUTH_USER_FRAGMENT}
`;

interface Props {
    children: ReactNode;
}

export function AuthProvider({ children }: Props) {
    const [user, setUser] = useState<AuthUser | null>(null);
    const [hasInit, setHasInit] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);

    async function refresh() {
        const { data } = await client.query<AuthProviderTokenCheck>({
            query: VIEWER_QUERY,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });

        if (!data || !data.viewer) {
            logout();
        } else {
            if (new URL(document.location.href).searchParams.get('noadmin')) {
                data.viewer.isAdmin = false;
            }

            setUser(data.viewer);
        }
    }

    async function checkToken() {
        if (getToken()) {
            await refresh();
        } else {
            logout();
        }

        setHasInit(true);
    }

    useAuthChange(
        ({ authenticated }) => {
            setShowAuthModal(!authenticated);
        },
        () => {
            setShowAuthModal(false);
        },
    );

    useEffect(() => {
        checkToken();
    }, []);

    if (!hasInit) {
        return <Loading />;
    }

    function setToken(token: string | null) {
        if (token) {
            storeToken(token);
        } else {
            deleteToken();
        }
    }

    function logout() {
        deleteToken();
        setUser(null);
    }

    return (
        <Context.Provider value={{ user, setUser, setToken, logout, refresh }}>
            {showAuthModal && <LoginModal />}
            {children}
        </Context.Provider>
    );
}

export function RequireAuth({ children }: Props): JSX.Element {
    const user = useAuthUser();

    if (!user) {
        return <Redirect host={APP_HOST} to={loginPath(window.location.href)} />;
    }

    return <>{children}</>;
}
