declare global {
    interface Window {
        __god_mode: boolean;
    }
}

export function get() {
    return window.__god_mode || false;
}

export function enable() {
    window.__god_mode = true;
}

export function disable() {
    window.__god_mode = false;
}
