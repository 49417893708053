// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._38KHH776rrwAmt-e1O5yqY,._38KHH776rrwAmt-e1O5yqY *{box-sizing:border-box}._38KHH776rrwAmt-e1O5yqY{width:100%;margin-bottom:1.3em}._38KHH776rrwAmt-e1O5yqY label{color:var(--tint-normal);display:block;margin-bottom:.3em;font-size:.9em;font-weight:bolder}._38KHH776rrwAmt-e1O5yqY[data-tint=default]{--tint-normal: var(--color)}", "",{"version":3,"sources":["webpack://./components/UiKit/base.scss","webpack://./components/UiKit/Field.scss"],"names":[],"mappings":"AAGE,oDACE,qBAAA,CCDJ,yBAGE,UAAA,CACA,mBAAA,CAEA,+BACE,wBAAA,CACA,aAAA,CACA,kBAAA,CACA,cAAA,CACA,kBAAA,CAGF,4CACE,2BAAA","sourcesContent":["@use \"variables\";\n\n%uikit {\n  &, * {\n    box-sizing: border-box;\n  }\n}\n","@use \"variables\";\n@import \"base\";\n\n.field {\n  @extend %uikit;\n\n  width: 100%;\n  margin-bottom: 1.3em;\n\n  label {\n    color: var(--tint-normal);\n    display: block;\n    margin-bottom: 0.3em;\n    font-size: .9em;\n    font-weight: bolder;\n  }\n\n  &[data-tint=\"default\"] {\n    --tint-normal: var(--color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "_38KHH776rrwAmt-e1O5yqY"
};
export default ___CSS_LOADER_EXPORT___;
