import { Size, Tint } from './utils';
import cx from 'classnames';
import styles from './Field.scss?module';
import React, { ReactNode, useMemo } from 'react';
import useId from './useId';

interface Context {
    tint?: Tint;
    size?: Size;
    id: string;
    required: boolean;
    name: string;
}

export const Context = React.createContext<Context | null>(null);

interface Props {
    tint?: Tint;
    size?: Size;

    className?: string;
}

function fieldProps({ tint = 'default', size = 'm', className = '', ...props }: Props) {
    return {
        'data-tint': tint,
        'data-size': size,
        className: cx(styles.field, className),
        ...props,
    };
}

interface FieldProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, Props {
    label: string;
    required?: boolean;
    input: ReactNode;
}
export default function Field({ label, input, required = false, ...props }: FieldProps) {
    const name = useMemo(() => {
        return label
            .normalize()
            .toLowerCase()
            .replace(' ', '_')
            .replace(/[^a-z0-9-_]/i, '');
    }, []);
    const id = useId(name);

    const ctx: Context = { tint: props.tint, size: props.size, id, required, name };
    return (
        <Context.Provider value={ctx}>
            <div {...fieldProps(props)}>
                <label htmlFor={id}>
                    {label}
                    {required && ' *'}
                </label>
                {input}
            </div>
        </Context.Provider>
    );
}
