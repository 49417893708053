import * as React from 'react';
import { Suspense, useMemo } from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../utils/apollo';
import Loading from './Loading';
import { APP_HOST, PLATFORM_HOST } from '../utils/const';
import * as Sentry from '@sentry/react';
import '@fortawesome/fontawesome-free/css/all.css';
import { TranslatorProvider } from '../intl/intl';
import { AuthProvider } from './Auth/AuthProvider';

Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: window.ENV,
});

const Landing = React.lazy(() => import('./Landing/Landing'));
const App = React.lazy(() => import('./App/Entry'));
const OrganizationFront = React.lazy(() => import('./OrganizationFront/Entry'));

function HostnameRouter() {
    const host = useMemo(() => window.location.hostname, []);

    switch (host) {
        case PLATFORM_HOST:
            return <Landing />;
        case APP_HOST:
            return <App />;
        default:
            return <OrganizationFront />;
    }
}

function FallbackComponent() {
    return (
        <div>
            <p>We're sorry — something's gone wrong.</p>
            <p>Our team has been notified, and they will fix the issue as soon as possible.</p>
            <button onClick={() => Sentry.showReportDialog()}>Report the issue</button>
        </div>
    );
}

export default function Root() {
    const root = (
        <TranslatorProvider>
            <ApolloProvider client={client}>
                <AuthProvider>
                    <Suspense fallback={<Loading />}>
                        <HostnameRouter />
                    </Suspense>
                </AuthProvider>
            </ApolloProvider>
        </TranslatorProvider>
    );

    if (process.env.NODE_ENV != 'production') {
        return root;
    }

    return (
        <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
            {root}
        </Sentry.ErrorBoundary>
    );
}
