import { getUrl } from './url';

export function getAssetsUrl(path = '') {
    return getUrl('assets', path);
}

export function staticAssetsUrl(path: string) {
    return getAssetsUrl(`/static${path}`);
}

export function staticSvgAssetsUrl(path: string) {
    return staticAssetsUrl(`/svg${path}`);
}
