import Field from '../UiKit/Field';
import { TextInput } from '../UiKit/Input';
import Button, { DivButton } from '../UiKit/Button';
import { pwdResetRequestPath } from '../../utils/apppaths';
import useTranslator, { Trans } from '../../intl/intl';
import * as React from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { LoginMutation, LoginMutationVariables } from '../../generated/LoginMutation';
import { useApolloClient } from 'react-apollo';
import { AUTH_USER_FRAGMENT, useAuth } from '../../hooks/useAuth';
import { gql } from 'apollo-boost';
import { useHistory } from 'react-router';

const LOGIN_MUTATION = gql`
    mutation LoginMutation($email: String!, $password: String!, $otp: String) {
        login(email: $email, password: $password, otp: $otp) {
            token
            user {
                ...AuthUser
            }
        }
    }
    ${AUTH_USER_FRAGMENT}
`;

interface Props {
    onSuccess?: () => void;
}

export default function LoginForm({ onSuccess }: Props) {
    const client = useApolloClient();
    const { user, setUser, setToken } = useAuth();
    const [t] = useTranslator();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(user ? user.email : '');
    const [password, setPassword] = useState('');
    const [otpRequired, setOtpRequired] = useState(false);
    const [otp, setOtp] = useState('');

    const emailRef = useRef<HTMLInputElement>(null);
    const otpRef = useRef<HTMLInputElement>(null);
    useLayoutEffect(() => {
        emailRef.current && emailRef.current.focus();
    }, [emailRef]);
    useLayoutEffect(() => {
        if (otpRequired) {
            otpRef.current && otpRef.current.focus();
        }
    }, [otpRequired]);

    async function doLogin(e: React.SyntheticEvent) {
        e.preventDefault();

        if (loading) {
            return;
        }

        setLoading(true);

        const { data, errors } = await client.mutate<LoginMutation, LoginMutationVariables>({
            mutation: LOGIN_MUTATION,
            variables: { email, password, otp },
            errorPolicy: 'all',
        });

        if (errors && errors.length > 0) {
            if (!otpRequired && errors.find((e) => e.extensions && e.extensions.code == 'OTP_REQUIRED')) {
                setOtpRequired(true);
                setLoading(false);
                return;
            }

            setLoading(false);
            alert(errors.reduce((acc, e) => `${acc}\n${e.message}`, 'Unable to login:'));
            return;
        }

        if (!data || !data.login || !data.login.user) {
            setLoading(false);
            alert('Unable to login');
            return;
        }

        setUser(data.login.user);
        setToken(data.login.token);

        if (onSuccess) {
            onSuccess();
        }
    }

    const showOtp = otpRequired || otp;

    return (
        <form onSubmit={doLogin}>
            <Field
                label={t('Email')}
                required
                input={
                    <TextInput
                        type="email"
                        innerRef={emailRef}
                        value={email}
                        autoComplete={'email'}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                }
            />

            <Field
                label={t('Password')}
                required
                input={
                    <TextInput
                        type="password"
                        autoComplete={'current-password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                }
            />

            <div style={showOtp ? {} : { height: 0, overflow: 'hidden' }}>
                <Field
                    label={t('OTP')}
                    required={otpRequired}
                    input={
                        <TextInput
                            type="text"
                            tabIndex={showOtp ? undefined : -1}
                            innerRef={otpRef}
                            minLength={6}
                            maxLength={6}
                            autoComplete={'one-time-code'}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    }
                />
            </div>

            <Button disabled={loading} type={'submit'} appearance={'solid'} tint={'primary'}>
                {t('Login')}
            </Button>
            <DivButton tint={'default'} onClick={() => history.push(pwdResetRequestPath())}>
                <Trans i18nKey={'forgot_password_question'}>Forgot Password?</Trans>
            </DivButton>
        </form>
    );
}
