import * as React from 'react';
import styles from './Loading.scss?module';

export default function Loading() {
    return (
        <div className={styles.container}>
            <div className={styles.loader} />
        </div>
    );
}
