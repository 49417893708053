import parseISO from 'date-fns/parseISO';
import formatRFC3339 from 'date-fns/formatRFC3339';

export function format3339(date: Date) {
    return formatRFC3339(date);
}

export function parse3339(str: string) {
    return parseISO(str);
}
