import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as H from 'history';

interface Props {
    to: string;
    host?: string;
}

export function redirect(history: H.History, to: string, host?: string | undefined) {
    const newUrl = new URL(to, window.location.origin);
    const oldOrigin = newUrl.origin;

    if (host) {
        newUrl.host = host;
    }

    const newOrigin = newUrl.origin;

    if (oldOrigin == newOrigin) {
        history.replace(newUrl.href.replace(newOrigin, ''));
    } else {
        window.location.href = newUrl.href;
    }
}

export default function Redirect({ to, host }: Props) {
    const history = useHistory();

    useEffect(() => {
        redirect(history, to, host);
    }, []);

    return null;
}
