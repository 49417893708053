import { useEffect } from 'react';

interface AuthEvent {
    authenticated: boolean;
}

const retry = 'auth_retry';
const change = 'auth_change';
const cancel = 'auth_cancel';

export function dispatchRetry() {
    document.dispatchEvent(new CustomEvent(retry));
}

export function dispatchCancel() {
    document.dispatchEvent(new CustomEvent(cancel));
}

export function useAuthChange(cb: (e: AuthEvent) => void, onCancel: () => void) {
    function handleChange(e: CustomEvent<AuthEvent>) {
        cb(e.detail);
    }

    function handleCancel() {
        onCancel();
    }

    useEffect(() => {
        document.addEventListener(change, handleChange);
        document.addEventListener(cancel, handleCancel);

        return () => {
            document.removeEventListener(change, handleChange);
            document.removeEventListener(cancel, handleCancel);
        };
    }, []);
}

export function handleUnauthenticated(cb: () => void) {
    function clearListeners() {
        document.removeEventListener(retry, handleRetry);
        document.removeEventListener(cancel, handleCancel);
    }

    function handleRetry() {
        clearListeners();

        cb();
    }
    document.addEventListener(retry, handleRetry);

    function handleCancel() {
        clearListeners();
    }
    document.addEventListener(cancel, handleCancel);

    document.dispatchEvent(
        new CustomEvent<AuthEvent>(change, { detail: { authenticated: false } }),
    );

    return dispatchCancel;
}
