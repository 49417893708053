import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages = [
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'Français' },
];

const inst = i18n.createInstance();

// The language is in the code itself, for any namespace, return the key as the value
const NaturalKeyResource = new Proxy(
    {},
    {
        // Get namespace
        get: function () {
            return new Proxy(
                {},
                {
                    // Get translation for key
                    get: function () {
                        return undefined; // Should return the default value
                    },
                },
            );
        },
    },
);

inst
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng(code) {
            return code.substr(0, 2);
        },
        resources: {
            en: NaturalKeyResource,
        },
        // debug: process.env.NODE_ENV != 'production',
        load: 'languageOnly',
        supportedLngs: languages.map((l) => l.code),
        ns: 'gen',
        defaultNS: 'gen',
        cleanCode: true,
        returnEmptyString: false,
        returnNull: false,
        partialBundledLanguages: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
    });

export default inst;
