declare global {
    interface Window {
        ENV: string;
        PLATFORM_DOMAIN: string;
        MAPBOX_API_KEY: string;
        GA_ID: string;
        SENTRY_DSN: string;
    }
}

export const PLATFORM_HOST = window.PLATFORM_DOMAIN;
export const APP_HOST = `app.${PLATFORM_HOST}`;

export const COLORS = ['#0000FF', '#FF0000', '#33cc33', '#cc33ff', '#663300'];

export const MAPBOX_TOKEN = window.MAPBOX_API_KEY;
