import { getUrl } from './url';

export function getAPIUrl(path = '') {
    return getUrl('api', path);
}

export function getStreetsSvgPath(bbox: [number, number, number, number], color: string) {
    return getAPIUrl(`/streets-svg?bbox=${bbox.join(',')}&color=${encodeURIComponent(color)}`);
}

export function routePath(orgId: string, id: string, path = '') {
    return getAPIUrl(`/orgs/${orgId}/routes/${id}${path}`);
}

export function routeStaticMapPath(orgId: string, id: string, style: string, width: number, height: number) {
    return routePath(orgId, id, `/static/${style}/${width}/${height}`);
}

export function routeGeoJSONPath(orgId: string, id: string, type = 'default') {
    return routePath(orgId, id, `/geojson.json?type=${type}`);
}

export function draftRouteStaticMapPath(orgId: string, id: string, style: string, width: number, height: number) {
    return getAPIUrl(`/orgs/${orgId}/draft-routes/${id}/static/${style}/${width}/${height}`);
}
