import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { getToken } from './token';
import { getAPIUrl } from './apipaths';
import * as God from './god';
import { createUploadLink } from 'apollo-upload-client';
import i18n from './i18next';
import { onError } from 'apollo-link-error';
import { handleUnauthenticated } from './auth_intercept';
import Observable from 'zen-observable';

const httpLink = createUploadLink({ uri: getAPIUrl('/graphql') });

function buildContext() {
    const headers: Record<string, any> = {};

    const token = getToken();
    if (token) {
        headers['authorization'] = `Bearer ${token}`;
    }

    if (God.get()) {
        headers['X-God'] = 'true';
    }

    headers['Accept-Language'] = i18n.language;

    return {
        headers,
    };
}

const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(buildContext());

    // Call the next link in the middleware chain.
    return forward(operation);
});

const errorLink = onError(({ graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            if (err.extensions && err.extensions['code'] == 'UNAUTHORIZED') {
                return (new Observable((observer) => {
                    return handleUnauthenticated(() => {
                        operation.setContext(buildContext());

                        forward(operation).subscribe(observer);
                    });
                }) as unknown) as ReturnType<typeof forward>;
            }
        }
    }
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
});

export default client;
