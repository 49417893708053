import { getUrl } from './url';
import * as API from './apipaths';
import { getToken } from './token';
import format from 'date-fns/format';
import { parse3339 } from './time';

export function getAppUrl(path = '') {
    return getUrl('app', path);
}

export function loginPath(next?: string) {
    if (next) {
        return `/login?next=${encodeURIComponent(next)}`;
    }

    return `/login`;
}

export function magicLoginPath() {
    return `/magic-login`;
}

export function magicLoginDonePath() {
    return `/magic-login/done`;
}

export function registerPath() {
    return `/register`;
}

export function pwdResetRequestPath(email?: string) {
    if (email) {
        return `/pwd-reset/request?email=${encodeURIComponent(email)}`;
    }

    return `/pwd-reset/request`;
}

export function pwdResetPath() {
    return `/pwd-reset`;
}

export function docsPath(path = '') {
    return `/docs${path}`;
}

export function orgsPath(path = '') {
    return `/orgs${path}`;
}

export function orgPath(id: string, path = '') {
    return orgsPath(`/${id}${path}`);
}

export function routesPath(orgId: string, path = '') {
    return orgPath(orgId, `/routes${path}`);
}

export function routePath(orgId: string, id: string, path = '') {
    return routesPath(orgId, `/${id}${path}`);
}

export function gpsLoggerPath(orgId: string, id: string, occurringDate: string) {
    const filename = encodeURIComponent(format(parse3339(occurringDate), 'yyyy-MM-dd_HH:mm'));

    return `gpslogger://properties/${API.routePath(
        orgId,
        id,
        `/gpslogger/${filename}?token=${getToken()}&nocache=${new Date().getTime()}`,
    )}`;
}

export function draftRoutesPath(orgId: string, path = '') {
    return orgPath(orgId, `/draft-routes${path}`);
}

export function draftRoutePath(orgId: string, id: string, path = '') {
    return draftRoutesPath(orgId, `/${id}${path}`);
}

export function membersPath(orgId: string, path = '') {
    return orgPath(orgId, `/members${path}`);
}

export function memberPath(orgId: string, id: string, path = '') {
    return membersPath(orgId, `/${id}${path}`);
}

export function aliasesPath(orgId: string, path = '') {
    return orgPath(orgId, `/aliases${path}`);
}

export function aliasPath(orgId: string, id: string, path = '') {
    return aliasesPath(orgId, `/${id}${path}`);
}

export function datasetsPath(path = '') {
    return `/datasets${path}`;
}

export function datasetPath(id: string, path = '') {
    return datasetsPath(`/${id}${path}`);
}

export function usersPath(path = '') {
    return `/users${path}`;
}

export function userPath(id: string, path = '') {
    return usersPath(`/${id}${path}`);
}

export function profilePath() {
    return '/profile';
}
