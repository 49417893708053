// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2E3SYMXYhwCxmvkPsyRQ7d{width:100%;height:100%;display:flex;justify-content:center;align-items:center}._2E3SYMXYhwCxmvkPsyRQ7d ._1n42p7RRAMUfiL7oU_mXF3{border:5px solid #e6e6e6;border-top:5px solid #005dcc;border-radius:50%;width:30px;height:30px;animation:_1y8oIYaLVxL6T1sLWvfDpc .8s linear infinite}@keyframes _1y8oIYaLVxL6T1sLWvfDpc{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}", "",{"version":3,"sources":["webpack://./components/Loading.scss"],"names":[],"mappings":"AAEA,yBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,kDACE,wBAAA,CACA,4BAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA,CACA,qDAAA,CAGF,mCACE,GAAA,sBAAA,CACA,KAAA,wBAAA,CAAA","sourcesContent":["@import 'src/utils/variables';\n\n.container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .loader {\n    border: 5px solid darken(white, 10%);\n    border-top: 5px solid $primary;\n    border-radius: 50%;\n    width: 30px;\n    height: 30px;\n    animation: spin .8s linear infinite;\n  }\n\n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_2E3SYMXYhwCxmvkPsyRQ7d",
	"loader": "_1n42p7RRAMUfiL7oU_mXF3",
	"spin": "_1y8oIYaLVxL6T1sLWvfDpc"
};
export default ___CSS_LOADER_EXPORT___;
