import React, { ReactNode } from 'react';
import {
    DefaultNamespace,
    I18nextProvider,
    Namespace,
    TFuncKey,
    Trans as ITrans,
    TransProps,
    useTranslation,
} from 'react-i18next';
import i18n, { languages } from '../utils/i18next';

export function TranslatorProvider({ children }: { children: ReactNode }) {
    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export function Trans<
    K extends TFuncKey<N> extends infer A ? A : never,
    N extends Namespace = DefaultNamespace,
    E extends Element = HTMLDivElement
>(props: TransProps<K, N, E>) {
    const [, i18n] = useTranslation();

    return <ITrans {...props} i18n={i18n} />;
}

export default function useTranslator() {
    const [t, i18n] = useTranslation();

    return [
        t,
        {
            locale: i18n.language.substr(0, 2),
            locales: Object.keys(languages),
            languages,
            setLocale(l: string) {
                return i18n.changeLanguage(l);
            },
        },
    ] as const;
}
