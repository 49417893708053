import React from 'react';
import LoginForm from './LoginForm';
import { dispatchRetry } from '../../utils/auth_intercept';
import Modal from '../UiKit/Modal';
import { staticSvgAssetsUrl } from '../../utils/assetspaths';
import { Trans } from '../../intl/intl';

export default function LoginModal() {
    return (
        <Modal>
            <img style={{ width: '60%' }} src={staticSvgAssetsUrl('/logo.svg')} />
            <br />
            <br />
            <div>
                <Trans>Your session has expired, please login to continue</Trans>
            </div>
            <br />
            <LoginForm onSuccess={() => dispatchRetry()} />
        </Modal>
    );
}
